<template>
    <div class="action-noti">
        <div class="title" v-html="msg" />
        <div class="body" v-if="body">
            <div v-html="body" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionNoti',
    props: ['message'],
    computed: {
        content() {
            return this.$mustParse(this.message.content) || {}
        },
        mType() {
            return this.message.mtype
        },
        body() {
            return '회원에게 채팅방 사라짐과 매칭매니저 배정됨을 안내 후 “심사승인”을 눌러주세요.'
        },
        msg() {
            return this.content.msg
        },
    },
}
</script>
